import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { css } from 'styled-components'
import { Helmet } from 'react-helmet'

import {
  space,
  mediaQueries,
  mainOffset,
} from '../utils/tokens'

import RichArticle from '../components/RichArticle'
import TableOfContents from '../components/TableOfContents'
import ArticleByline from '../components/Article/ArticleByline'
import Container from '../components/Container'
import SEO from '../components/SEO'
import { MainLayout } from '../components/MainLayout'
import { HeroSelector } from '../components/HeroSelector'
import { BlogPostJsonLd, FAQJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import siteConfig from '../utils/siteConfig'

const GuideTemplate = ({ data, location }) => {
  const {
    hero,
    body,
    publishDateISO,
    publishDate,
    modifiedDate,
    metaDescription,
    metaTitle,
    metaImage,
    title,
    fields,
    frequentlyAskedQuestions,
    datePublished,
    dateModified,
    authorName
  } = data.contentfulPage
  
  const postNode = {
    publishDateISO: publishDateISO,
    title: metaTitle,
    metaDescription,
    heroImage: hero?.backgroundPhoto,
  }

  const sanitizedFaqs =
    frequentlyAskedQuestions?.length > 0 &&
    frequentlyAskedQuestions.map(faq => {
      return {
        question: faq.question,
        answer: faq.answer && faq.answer.childMarkdownRemark.html,
      }
    })


  const url = `${siteConfig.siteUrl}${fields.path}`

  const ogImages = metaImage && [
    {
      url: `https:${metaImage.file?.url}`,
      alt: metaImage.title,
      width: 1200,
      height: 627,
    },
  ]
  
  return (
    <MainLayout>
      <GatsbySeo
        title={
          metaTitle ? metaTitle : `${title} | PrintAWorld`
        }
        description={metaDescription?.internal?.content}
        openGraph={{
          url: url,
          title: metaTitle,
          description: metaDescription?.internal?.content,
          images: ogImages,
        }}
      />
      <BlogPostJsonLd
        url={url}
        title={title}
        images={[metaImage?.file?.url]}
        datePublished={datePublished}
        dateModified={dateModified}
        authorName="PrintAWorld"
        authorType="Organization"
        publisherName="PrintAWorld"
      />
      {sanitizedFaqs?.length > 0 && <FAQJsonLd questions={sanitizedFaqs} />}
      <main>
        {hero && <HeroSelector hero={hero} />}
        <Container
          css={css({
            padding: `${space[4]}px ${space[4]}px ${space[4]}px`,
            [mediaQueries.xl]: {
              padding: `${space[5]}px ${space[4]}px ${space[5]}px`,
            },
          })}
        >
          <article>
            {body && (
              <div
                css={css({
                  padding: `0 0 ${space[4]}px`,
                  [mediaQueries.lg]: {
                    padding: `${space[4]}px 0 ${space[5]}px`,
                  },
                })}
              >
                <ArticleByline
                  publishDate={publishDate}
                  modifiedDate={modifiedDate}
                  timeToRead={fields?.readingTime?.text}
                />
              </div>
            )}
            {body && (
              <div
                css={css({
                  display: `flex`,
                  flexDirection: `column`,
                  [mediaQueries.lg]: {
                    flexDirection: `row-reverse`,
                  },
                })}
              >
                <div
                  css={css({
                    marginBottom: `${space[5]}px`,
                    [mediaQueries.lg]: {
                      width: `25%`,
                      paddingLeft: `${space[4]}px`,
                      marginBottom: 0,
                    },
                  })}
                >
                  <div
                    css={css({
                      position: `sticky`,
                      top: `${mainOffset}`,
                    })}
                  >
                    <TableOfContents json={body.raw} location={location} />
                  </div>
                </div>
                <div
                  css={css({
                    [mediaQueries.lg]: {
                      width: `75%`,
                    },
                  })}
                >
                  <div
                    css={css({
                      [mediaQueries.lg]: { marginRight: `${space[4]}px` },
                    })}
                  >
                    <RichArticle body={body} location={location} />
                  </div>
                </div>
              </div>
            )}
          </article>
        </Container>
      </main>
    </MainLayout>
  )
}

export default GuideTemplate

export const GuideTemplateQuery = graphql`
         query($slug: String!) {
           contentfulPage(slug: { eq: $slug }) {
             title
             id
             slug
             metaTitle
             metaImage {
               title
               file {
                 url
               }
             }
             fields {
               path
               readingTime {
                 text
               }
             }
             metaDescription {
               internal {
                 content
               }
             }
             body {
               raw
               references {
                 ...RichTextArticleFragment
               }
             }
             publishDate(formatString: "MMMM DD, YYYY")
             modifiedDate: updatedAt(formatString: "MMMM DD, YYYY")
             publishDateISO: publishDate(formatString: "YYYY-MM-DD")
             datePublished: publishDate
             dateModified: updatedAt
             hero {
               ... on Node {
                 __typename
                 ... on ContentfulHeroWithBlock {
                   ...HeroWithBlock
                 }
                 ... on ContentfulHeroTextOnly {
                   ...HeroTextOnly
                 }
                 ... on ContentfulHero {
                   title
                   backgroundPhoto {
                     title
                     ogimg: resize(width: 1800) {
                       src
                       width
                       height
                     }
                     fluid(maxHeight: 600, maxWidth: 1200) {
                       ...GatsbyContentfulFluid_withWebp
                     }
                   }
                 }
               }
             }
             frequentlyAskedQuestions {
               id
               question
               answer {
                 childMarkdownRemark {
                   html
                 }
               }
             }
           }
         }
       `